.pac-container {
  background-color: blue !important;
  z-index: 20;
  position: fixed;
  display: inline-block;
}

.modal {
  z-index: 20;
}

.modal-backdrop {
  z-index: 10;
}
.tagIcon {
  fill: black !important;
}
.orderConfirmDialogBodyContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (min-width: 768px) {
    gap: 32px;
  }
  .orderConfirmTitle {
    font-size: 24px;
    font-weight: 700;
    text-align: left;
    @media (min-width: 768px) {
      font-size: 32px;
      line-height: 32px;
    }
  }
  .orderConfirmSubTitle {
    display: flex;
    white-space: pre-wrap;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    text-align: left;
    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
}

.addressTagChip {
  width: 150px;
}

.cartConflictDilaogContent {
  width: 378px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  // width: '378px',
  // fontSize: '16px',
  // fontWeight: 600,
  // lineHeight: '24px',
  // background-color: red;
}
.emptyCartIcon {
  path {
    fill: white;
  }
}
.specialRequestsList {
  align-items: start;
}
.dropdownItem {
  padding-top: 8px;
  padding-bottom: 8px;
  width: 240px;
  &.selectedTagDropdownItem {
    cursor: default;
    &::before {
      background-color: transparent !important;
    }
  }
}

.tagAvatar {
  &.selectedTagDropdownItem {
    border: 1px solid !important;
  }
}

.tagLine {
  //styleName: Paragraph/P16;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  &.selectedTagDropdownItem {
    font-weight: 600 !important;
  }
}

.userInfoMethodBlock {
  // width: 580px;
  // max-width: 580px;
}

.autocomplete {
  text-indent: 10px;
  font-size: var(--sz-16, 16px);
  color: RGB(var(--content));
  padding: 0;
  background-color: transparent;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  color: #021d3d;
  min-height: 56px;
  // border-radius: 8px;
  border: solid 0.5px #b9c2ce;

  &:not(:focus)::-webkit-input-placeholder {
    color: RGB(var(--content)) !important;
    visibility: visible;
  }
}

.stateSelectWithOutSelectSign {
  appearance: none;
  // border-radius: 8px;
  border: 0px;

  // border: solid 0.5px #b9c2ce;
  // max-width: 300px;
  height: 100%;
  width: 100%;
  // width: fit-content;
  // padding: 4px 16px;
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--font-family) !important;
  color: inherit;
}

.notificationMessageContainer {
  width: 100%;
  line-height: 16px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 500;
}

.notificationMessageIcon {
  fill: rgba(51, 209, 167, 1);
}

.selectInputBase {
  padding: 0px !important;
  // height: ;
}

.selectInputWrapperClassName {
  height: 100%;
  height: 100%;
}

.stateSelect {
  border-radius: 8px;
  border: 0px;
  // border: solid 0.5px #b9c2ce;
  max-width: 300px;
  height: 56px;
  padding: 4px 16px;
}

@media screen and (min-width: 768px) {
  .stateSelect {
    // max-width: 200px;
  }

  .stateSelectWithOutSelectSign {
    // max-width: 200px;
  }
}

.dialogBody {
  display: flex;
  flex-direction: column;
  gap: 74px;
  height: 100%;
}

@media (min-width: 768px) {
  .dialogBody {
    display: flex;
    flex-direction: row;
  }
}
