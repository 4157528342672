.show {
  opacity: 1;
  max-height: 1000px;
  transition: opacity 0.7s ease-in-out, max-height 0.7s ease-in-out;
}
.hidden {
  opacity: 0;
  max-height: 0px;
  transition: opacity 0.7s ease-in-out, max-height 0.7s ease-in-out;
}
