.accordionIcon {
  width: 24px;
  height: 24px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 24px;
    height: 2px;
    background-color: #000;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 2px;
    height: 24px;
    background-color: #000;
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transition: all 0.5s ease-in-out;
  }
  &.accordionMinus {
    &:after {
      -moz-transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      margin-top: -12px;
    }
  }
}
.accordianBody{
  min-height: 0px;
  overflow: auto;
  // transition-duration: 3s !important;
}
.editOrderMessageCard {
  min-height: 400px !important;
}

