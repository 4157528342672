.filtersWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.returnAsSimpleList {
  min-width: 250px;
}

.filters {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: end; 
}

@media screen and (min-width: 720px) {
  .filters {
    gap: 16px;
  }
}
