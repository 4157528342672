.checkoutPageBody {
  column-gap: 80px;
  @media screen and (max-width: 720px) {
    flex-direction: column-reverse;
  }
  .breakdown {
    @media screen and (min-width: 720px) {
      min-width: 420px;
    }
  }
  .payButton {
    &:not(.isMobile) {
      border-radius: 8px !important;
    }
    &.isMobile {
      z-index: 10;
    }
  }
}
