.headerDeliveryDetails {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: black;
}
.xxlFooterButton {
  z-index: 6;
  @media screen and (min-width: 720px) {
    position: sticky;
    bottom: -64px;
  }
}
.timeLine {
  //   max-width: 200px;
  margin-top: 20px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  /* Primary / Primary Grey */

  color: #1b2124;
}

.editOrderStartContent {
  max-width: 382px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 14px;
  font-weight: 600;
  white-space: pre-wrap;
}
.editOrderStartDialogHeaderTitle {
  display: flex;
  align-items: center;
  gap: 12px;
}
.deliveryDetails {
  //   max-width: 200px;
  margin-top: 20px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  display: grid;
  row-gap: 20px;
  /* identical to box height, or 100% */

  /* Primary / Primary Grey */

  color: #1b2124;
}
.timeLine > div {
  margin: 0px;
  padding: 0px;
}
.timeLineDisabled {
  color: #cccccc;
}
.vl {
  width: 2px;

  margin: 1px;
  height: 34px;
  position: absolute;
  margin-left: 13px;
  /* Primary/Teddly Green (200) */

  background: #33d1a7;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
.divider {
  margin-top: 30px;
  margin-bottom: 30px;
}
.whereMyOrderButton {
  /* Primary / Primary Grey */
  border: 1px solid #1b2124;
  border-radius: 2px;

  /* Save changes */

  width: 165px;
  height: 24px;
  background: #ffffff;
  padding: 3px;
  width: 165px;
  height: 28px;
  margin-top: 20px;
  /* Paragraph/P14 - Bold */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  text-align: center;

  /* Primary / Primary Grey */

  color: #1b2124;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.vlDisabled {
  background: #efefef;
}
.dateCreated {
  // margin-top: ;
  color: #8d9091;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
.deliveryTitle {
  margin-top: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  /* identical to box height, or 200% */
  text-transform: uppercase;
  /* Grey / Grey #1 */
  color: #8d9091;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.deliveryInfo {
  margin-top: 25px;
  margin-bottom: 25px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  color: #000000;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.cardDeliveryDetailsContent {
  margin: 20px;
}
.deliveryDetailsStatus {
  margin-top: 20px;
  margin-bottom: 40px;
}
.sectionRowIcon {
  svg {
    width: 30px !important;
    height: 30px !important;
  }
}
