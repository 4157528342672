.deliveryInHeader {
  // display: none;
  @media screen and (min-width: 1024px) {
    display: block;
  }
}

.selectDeliveryTimeButton {
  text-transform: none !important;
}
.saveChangesButton {
  border-radius: 8px !important;
}

.dialogContainer {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.userInfoBlock {
  display: flex;
  flex-direction: column;
}

.nowCard {
  // @extend .ted-h4;
  height: fit-content;
}

.nowCardImage {
  background-color: transparent !important;
  max-width: 45%;
  background-position: center !important;
}

.cardDescription {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.cardDescriptionTitle {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
}

.newTitle {
  display: flex;
  gap: 8px;
  align-items: end;
}
