.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.buttons {
  display: flex;
  gap: 16px;
}

.line {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (min-width: 960px) {
  .line {
    flex-direction: row;
  }
}
