.microMargin {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  width: 100%;
  max-width: 60px;
  background-color: #efefef;
  border: none;
  border-radius: 4px;
  height: 35px;
  
  padding: 8px 10px 8px 10px;
  user-select: none;
  text-transform: capitalize;
  & [class*='leadingIcon'] {
    background-color: #f6f6f6;
    margin: 2px 0px 3px 0px;
    // padding: 0px;
    > svg {
      width: 19px;
      height: 19px;
    }
  }
  > span {
    // padding: 0px 8px 0px 0px !important;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    // color: #343440 !important;
  }
}
.selectedType {
  text-transform: capitalize;
  border-radius: 4px;
  &[class*='chip'] {
    &[class*='focusable'] {
      &:hover {
        background-color: #343440 !important;
      }
      &:focus {
        background-color: #343440 !important;
        color: #fff;
      }
    }
  }
  // width: auto;
  background-color: #343440;
  border: none;
  height: 35px;
  padding: 0px 11px 0px 18px;
  user-select: none;
  & [class*='selected'] {
    &:focus {
      background-color: transparent;
    }
  }
  &:hover {
    background-color: #27272f ;
    > span {
      color: #fff !important;
    }
    & [class*='leadingIcon'] {
      > svg {
        fill: #fff;
      }
    }
  }
  & [class*='leadingIcon'] {
    background-color: transparent;
    margin: 2px 0px 3px 0px;
    padding: 0px;
    > svg {
      fill: #fff;
      width: 19px;
      height: 19px;
    }
  }
  > span {
    padding: 0px 8px 0px 0px !important;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    color: #fff !important;
  }
  // width: 100%;
}
