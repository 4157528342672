.largeSuggestionItemsList {
  grid-template-columns: repeat(auto-fill, minmax(178px, 50%));
}
.itemClassName {
  min-width: auto !important;
}
@media screen and (min-width: 720px) {
}

.autocompleteLocationInput {
  z-index: 1;
}
