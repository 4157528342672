.productDescription {
  background-color: rgba(39, 39, 42, 0.5);
  z-index: var(--zDialog);
}
.cardContainer {
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);
  .cardHeader {
    span {
      font-family: "Poppins", sans-serif;
    }
    button {
      svg {
        fill: #222127;
      }
    }
  }

  .cardBody {
    background-color: rgba($color: #02234b, $alpha: 0.03);
    .stickyProduct {
      transform: translateX(57px);
    }
    .stickyProductDescription {
      top: 46rem;
    }

    h2 {
      font-family: "Poppins", sans-serif;
    }

    // .editIcon {
    //   svg {
    //     stroke: #888;
    //   }
    // }
    .editIconSelected {
      background-color: #222127;
      svg {
        fill: #fff;
      }
    }

    .ProductDescriptionBar {
      top: 45rem;
    }

    .price {
      font-family: "Poppins", sans-serif;
    }

    .separate {
      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 0;
        bottom: 0;
        border-left: solid 1px #dddddd;
        transform: rotate(7deg) translateX(-50%);
      }
    }
    .regPrice {
      font-family: "Poppins", sans-serif;
    }
    .totalPrice {
      font-family: "Poppins", sans-serif;
    }

    p {
      font-family: "Poppins", sans-serif;
    }

    .ShowMoreButton {
      font-family: "Poppins", sans-serif;
    }
    .productTitle {
      font-size: 18px; //retail - 32 תיאור מוצר

      @media only screen and (min-width: 760px) {
        //retail
        font-size: 32px;
      }
    }
  }
  .estLabel {
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.4px;
    color: #bbbbbb;
  }
}
.EditButton {
  background-color: #222127;
  z-index: calc(var(--zDialog) + 10);
  > div {
    width: 12px !important;
    height: 12px !important;

    > div {
      background-color: #222127 !important;
      border-radius: 0 0 0 0.2em !important;
    }
  }
}

.descriptionClick {
  // display: flex;
  // flex-direction: column;
  // gap: 12px;
}
.imageContainer {
  padding-top: 90%;
  position: relative;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    > img {
      object-fit: contain;
    }
  }
}
