.image {
  display: none;
}
.orderConfirmationDialog {
  max-width: 100%;
}
@media (min-width: 960px) {
  .image {
    display: block;
  }
  .orderConfirmationDialog {
    max-width: 70%;
  }
}
.dialogBody {
  display: flex;
  flex-direction: column;
  gap: 74px;
  height: 100%;
}

.dialogBodySectionsContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 328px;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.dialogTitle {
  //styleName: Heading/H3;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}
.dialogBodySubTitle {
  font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 20px;
text-align: left;
color: rgba(141, 144, 145, 1);

}

.dialogBodySection {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  &.horizontalSection {
    flex-direction: row;
  }
  &.socialLoginSection {
    gap: 12px;
  }
}

.autoWidth {
  // width: auto !important;
}
.formOrDivider {
  //styleName: Label/L12;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: rgba(141, 144, 145, 1);
}

@media (min-width: 768px) {
  .dialogBody {
    display: flex;
    flex-direction: row;
  }
}
